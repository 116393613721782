import Config from "./Config";
import Pages from "./Pages";

export default function Content({ action, config, pages }) {
  switch (action) {
    case "config":
      return <Config config={config} />;
    case "pages":
      return <Pages pages={pages} />;
    default:
      return <h1>Home</h1>;
  }
}
