import { useState, useEffect } from "react";
import Waiting from "../../Waiting";
import Parser from "rss-parser";
let parser = new Parser();
function Simulateurs() {
  const [items, setItems] = useState(false);
  const defSim = [
    { name: "Placement", id: "48554", icon: "", color: "#5496CD" },
    { name: "Emprunts", id: "10736", icon: "", color: "#CD4355" },
    { name: "Crédit-bail", id: "58717", icon: "", color: "#636E50" },
    { name: "Congés payés", id: "14260", icon: "", color: "#B8882A" },
    { name: "Autres", id: "35380", icon: "", color: "#787898" },
  ];
  useEffect(() => {
    if (!items) {
      parser
        .parseURL("https://actualites.hares-expertise.fr/rss/simulateurs")
        .then((data) => {
          const { items } = data;
          //   console.log(data);
          setItems(items);
        });
    }
  }, [items]);
  if (!items) {
    return <Waiting />;
  }
  return (
    <div className="Simulateurs bloc large">
      {items.map((item, index) => {
        // console.log(item);
        let Sim = defSim.find((x) => x.id === item.enclosure.length);
        return (
          <div className="sim" key={index}>
            <div
              className="back"
              style={{
                //   filter: `url('#teal-white')`,
                //   backgroundImage: `url(${item.enclosure.url})`,
                backgroundColor: Sim.color,
                backgroundSize: "40%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
            <div className="name">{item.title}</div>
            {/* {Sim.name} */}
          </div>
        );
      })}
    </div>
  );
}
export default Simulateurs;
