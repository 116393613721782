import "./styles.scss";
import { useEffect, useState } from "react";
import Waiting from "../../Waiting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Parser from "rss-parser";
let parser = new Parser();

function LastNews({ value = 3, items, flux, pagination }) {
  const [news, setNews] = useState(false);
  const [page, setPage] = useState({ min: 0, max: value, current: 0 });
  const defMonth = [
    "janv",
    "févr",
    "mars",
    "avr",
    "mai",
    "juin",
    "juillet",
    "août",
    "sept",
    "oct",
    "nov",
    "déc",
  ];
  useEffect(() => {
    if (!news) {
      if (!items) {
        parser
          .parseURL(
            flux ? flux : "https://actualites.hares-expertise.fr/rss/actus/all"
          )
          .then(({ items }) => {
            setNews(items);
          });
      } else {
        setNews(items);
      }
    }
  }, [news, items, flux]);

  if (!news) {
    return <Waiting />;
  }
  return (
    <div className="bloc all nopadding column">
      <div className="LastNews">
        {news.slice(page.min, page.max).map((item) => {
          let date = new Date(item.pubDate);
          // let now = new Date();
          // let text = "";
          // if (now.getTime() - date.getTime() > 1000 * 60 * 60 * 24) {
          // text += `${date.getDate()} ${defMonth[date.getMonth()]}`;
          // } else {
          //   text = "hier";
          //   if (now.getDate() === date.getDate()) {
          //     text = "aujourd'hui";
          //   }
          //   // text += ` à ${date.toTimeString().substr(0, 5)}`;
          // }
          let img = item.content.match(/src\s*=\s*"(.+?)"/)[1];
          return (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="new"
              key={item.guid}
            >
              <div
                className="img"
                style={{
                  backgroundImage: `url("${img}")`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></div>
              <div className="title">{item.title}</div>

              <div className="date">
                <span>{date.getDate()}</span> {defMonth[date.getMonth()]}
              </div>
              <div className="text">{item.contentSnippet}</div>
            </a>
          );
        })}
      </div>
      {pagination ? (
        <ul className="pagination">
          <li
            onClick={() => {
              if (page.current - 1 >= 0) {
                let min = (page.current - 1) * value;
                let max = page.current * value;
                setPage({ min, max, current: page.current - 1 });
              }
            }}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </li>
          {new Array(10).fill().map((a, index) => {
            return (
              <li
                className={page.current === index ? "actif" : ""}
                key={`page-${index}`}
                onClick={() => {
                  let min = index * value;
                  let max = (index + 1) * value;
                  setPage({ min, max, current: index });
                }}
              >
                {index + 1}
              </li>
            );
          })}
          <li
            onClick={() => {
              if (page.current + 1 <= 9) {
                let min = (page.current + 1) * value;
                let max = (page.current + 2) * value;
                // console.log({ min, max, current: page.current + 1 });
                setPage({ min, max, current: page.current + 1 });
              }
            }}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </li>
        </ul>
      ) : (
        false
      )}
    </div>
  );
}
export default LastNews;
