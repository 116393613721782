import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Custom from "./custom";

function Bloc({ bloc, page, indexBloc, sub = false }) {
  // console.log(indexBloc);
  let Legends = page.filter((x) => x.type === "legend");
  function findLegend(text) {
    let legends = Legends.filter((legend) => {
      return text.includes(legend.options);
    });
    if (legends.length > 0) {
      legends.forEach((legend) => {
        text = text.replace(
          legend.name + legend.options,
          `<span class='legend' data-legend='${legend.options}'>${legend.name}<div class="helper">${legend.value}</div></span>`
        );
      });
    }
    return text;
  }
  function checkValue(bloc) {
    if (bloc.value === "content") {
      return page
        .filter(
          (content) =>
            content.type === "content" && bloc.options === content.options
        )
        .map((content, index, self) => {
          switch (content.name) {
            case "ul":
              return (
                <ul
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                >
                  {self
                    .filter((li) => li.name === "li")
                    .map((li, i) => {
                      findLegend(page, bloc, li.value);
                      return (
                        <li
                          key={`li-${i + Math.random()}`}
                          className={li.class}
                          dangerouslySetInnerHTML={{
                            __html: findLegend(li.value),
                          }}
                        ></li>
                      );
                    })}
                </ul>
              );
            case "li":
              return false;
            case "iframe":
              let [width, height] = content.class.split("|");
              return (
                <iframe
                  key={`content-${index + Math.random()}`}
                  title="Google Map Hares"
                  loading="lazy"
                  style={{ border: 0 }}
                  src={content.value}
                  width={width}
                  height={height}
                  frameBorder="0"
                  allowFullScreen="allowfullscreen"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              );
            case "img":
              return (
                <img
                  className={content.class}
                  key={`content-${index + Math.random()}`}
                  src={content.value}
                  alt={content.value}
                />
              );
            case "imglink":
              return (
                <a
                  href={content.value}
                  key={`content-${index + Math.random()}`}
                  target="_blank"
                  rel="noreferrer"
                  className="imglink"
                  style={{
                    background: `url(${content.class})`,
                    backgroundSize: "80%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                >
                  {/* <img src={content.class} alt={content.value} /> */}
                </a>
              );
            case "h2":
              if (content.class === "separator") {
                return (
                  <>
                    <h2 key={`content-${index + Math.random()}`}>
                      {content.value}
                    </h2>
                    <div className="separator"></div>
                  </>
                );
              }
              return (
                <h2
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                >
                  {content.value}
                </h2>
              );
            case "h3":
              return (
                <h3
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                >
                  {content.value}
                </h3>
              );
            case "h4":
              return (
                <h4
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                >
                  {content.value}
                </h4>
              );
            case "p":
              return (
                <p
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                  dangerouslySetInnerHTML={{
                    __html: findLegend(content.value),
                  }}
                >
                  {/* {content.value} */}
                </p>
              );
            case "btn":
              return (
                <Link
                  key={`content-${index + Math.random()}`}
                  className="btn small"
                  to={content.class}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {content.value}
                </Link>
              );
            case "icon":
              return (
                <a
                  key={`content-${index + Math.random()}`}
                  className="icon"
                  href={content.value}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", content.class]} />
                </a>
              );
            case "link":
              return (
                <div className="link">
                  <a
                    key={`content-${index + Math.random()}`}
                    href={`${content.class}:${content.value}`}
                  >
                    {content.value}
                  </a>
                </div>
              );
            case "custom":
              return (
                <Custom
                  key={`content-${index + Math.random()}`}
                  bloc={content}
                />
              );

            default:
            case "div":
              return (
                <div
                  key={`content-${index + Math.random()}`}
                  className={content.class}
                >
                  {content.value}
                </div>
              );
          }
        });
    }
    switch (bloc.options) {
      case "image":
        return <img src={bloc.value} alt={bloc.value} />;
      case "image-link":
        return (
          <Link
            to={bloc[""]}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src={bloc.value} alt={bloc.value} />
          </Link>
        );
      default:
        return bloc.value;
    }
  }
  if (bloc.type === "sub-bloc" && !sub) return false;
  // console.log(bloc.type);
  switch (bloc.type) {
    case "carousel":
      return (
        <div
          className={`${bloc.type} ${bloc.class}`}
          key={"carousel" + indexBloc + Math.random()}
        >
          {bloc.value.split(",").map((img, index) => {
            return (
              <img
                src={`${img}`}
                key={`img-${index + Math.random()}`}
                alt={`${img}`}
              />
            );
          })}
        </div>
      );
    case "title":
      return (
        <div
          className={`${bloc.type} ${bloc.class}`}
          key={"title" + indexBloc + Math.random()}
        >
          <h1>{bloc.value}</h1>
        </div>
      );
    case "sub-title":
      return (
        <div
          className={`${bloc.type} ${bloc.class}`}
          key={"sub-title" + indexBloc + Math.random()}
        >
          <h2 className={`${bloc.class}`}>{bloc.value}</h2>
          {bloc.options === "separator" ? (
            <div className="separator"></div>
          ) : (
            false
          )}
        </div>
      );
    case "flip-bloc":
      return (
        <div
          className={`${bloc.type} ${bloc.class}`}
          key={"flip-bloc" + indexBloc + Math.random()}
        >
          <div className="inner">
            <div className="front">{bloc.value}</div>
            <div className="back">{bloc.options}</div>
          </div>
        </div>
      );
    case "bloc":
      let subBloc = page
        .filter((x) => x.name === bloc.name && x.parent)
        .map((subBloc, indexSubBloc) => {
          return (
            <Bloc
              bloc={subBloc}
              indexBloc={`sub-${indexBloc}-${indexSubBloc}`}
              page={page}
              sub={true}
              key={"sub" + indexSubBloc + Math.random()}
            />
          );
        });
      return (
        <div
          className={`${bloc.type} ${bloc.class}`}
          key={indexBloc + Math.random()}
        >
          {bloc.value === "none" ? subBloc : bloc.value}
        </div>
      );
    case "calltoaction":
      let [name, text, button, options] = bloc.value.split("|");
      if (options === "button") {
        return (
          <div className={`${bloc.type} ${bloc.class} ${options}`}>
            <div className={`name ${bloc.class}`}>{name}</div>
            <div className="text">{text}</div>
            <a href={bloc.options} className="btn invert">
              {button}
            </a>
          </div>
        );
      }
      return (
        <a
          href={bloc.options}
          className={`${bloc.type} ${bloc.class} ${options}`}
          key={indexBloc + Math.random()}
        >
          <div className={`name ${bloc.class}`}>{name}</div>
          <div className="text">{text}</div>
        </a>
      );
    case "sub-bloc":
      if (sub) {
        return (
          <div
            className={`${bloc.type} ${bloc.class}`}
            key={"sub-bloc" + indexBloc + Math.random()}
          >
            {checkValue(bloc)}
          </div>
        );
      }
      return false;
    case "lilbar":
      return <div className={`${bloc.type} ${bloc.class}`}></div>;
    case "custom":
      return <Custom bloc={bloc} page={page} />;
    default:
      return <></>;
  }
}
export default Bloc;
