import "./styles.scss";
import { Link } from "react-router-dom";
function CalltoActionWhoIam() {
  let def = [
    {
      name: "Qui sommes-nous ?",
      link: "/cabinet-comptabilite",
      img: "/static/qui-sommesnous-1.png",
    },
    {
      name: "Nous rencontrer",
      link: "/meetus",
      img: "/static/rencontre-140x100.png",
    },
    {
      name: "Nos Valeurs",
      link: "/ourvalor",
      img: "/static/valeur-140x100.png",
    },
    {
      name: "Nous trouver",
      link: "/findus",
      img: "/static/geolocalisation-140x100.png",
    },
  ];
  return (
    <div className="CalltoActionWhoIam">
      <div className="moreFar">
        <h3>Aller Plus Loin</h3>
        <div className="separator"></div>
        <div className="links">
          {def
            .filter((x) => x.link !== window.location.pathname)
            .map((link, index) => {
            //   console.log(link);
              return (
                <Link
                  to={link.link}
                  className="link"
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={link.img} alt={link.name} />
                  <div className="text">{link.name}</div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CalltoActionWhoIam;
