import { useState } from "react";
import "./styles.scss";
export default function MEGVideos({ bloc }) {
  const Videos = [
    {
      title: "Présentation",
      icn: "presentation",
      link: "https://player.vimeo.com/video/249773871",
    },
    {
      title: "Facturation",
      icn: "facturation",
      link: "https://player.vimeo.com/video/257511903",
    },
    {
      title: "Notes de frais",
      icn: "notedefrais",
      link: "https://player.vimeo.com/video/257521554",
    },
    {
      title: "Caisse",
      icn: "caisse",
      link: "https://player.vimeo.com/video/257506840",
    },
    {
      title: "Banque",
      icn: "banque",
      link: "https://player.vimeo.com/video/257500190",
    },
    {
      title: "Achats",
      icn: "basket",
      link: "https://player.vimeo.com/video/143632367",
    },
    {
      title: "Banque BNC",
      icn: "banque",
      link: "https://player.vimeo.com/video/257501379",
    },
  ];

  const [currentVideo, setCurrentVideo] = useState(Videos[0]);

  return (
    <div className="MEGVideos">
      <div className="video">
        <iframe
          title={currentVideo.title}
          src={currentVideo.link}
          width="550"
          height="310"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="listVideo">
        {Videos.map((video) => (
          <div
            className="linkVideo"
            onClick={() => setCurrentVideo(video)}
            key={video.title}
          >
            <img
              src={`/static/icone-${video.icn}.png`}
              alt={`icone-${video.icn}`}
              width="36px"
            />
            {video.title}
          </div>
        ))}
      </div>
    </div>
  );
}
