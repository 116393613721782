import imgSocial from "./img/social.jpg";
import imgFiscalite from "./img/fiscalite.jpg";
import imgJuridique from "./img/juridique.jpg";
import imgGestion from "./img/gestion.jpg";
import imgHighTech from "./img/high-tech.jpg";
import imgPatrimoine from "./img/patrimoine.jpg";
import imgMetiers from "./img/metiers.jpg";

function Thematiques({ styles, setCurrent }) {
  // console.log(setCurrent);
  const defThema = [
    {
      name: "Social",
      title: "Social",
      img: imgSocial,
      link: "social",
      flux: "https://actualites.hares-expertise.fr/rss/actus/social/120",
    },
    {
      name: "Fiscalité",
      title: "Fiscalité",
      img: imgFiscalite,
      link: "fiscalite",
      flux: "https://actualites.hares-expertise.fr/rss/actus/fiscal/120",
    },
    {
      name: "Juridique",
      title: "Juridique",
      img: imgJuridique,
      link: "juridique",
      flux: "https://actualites.hares-expertise.fr/rss/actus/juridique/120",
    },
    {
      name: "Gestion",
      title: "Gestion",
      img: imgGestion,
      link: "gestion",
      flux: "https://actualites.hares-expertise.fr/rss/dossiers/gestion/120",
    },

    {
      name: "High-Tech",
      title: "High-Tech",
      img: imgHighTech,
      link: "hightech",
      flux: "https://actualites.hares-expertise.fr/rss/actus/multimedia/120",
    },
    {
      name: "Patrimoine",
      title: "Patrimoine",
      img: imgPatrimoine,
      link: "patrimoine",
      flux: "https://actualites.hares-expertise.fr/rss/actus/patrimoine/120",
    },
    {
      name: "Métiers",
      title: "Métiers",
      img: imgMetiers,
      link: "metiers",
      flux: false,
    },
  ];
  return (
    <div className={`Thematiques bloc large row ${styles}`}>
      {defThema.map((thematique) => {
        return (
          <div
            onClick={() => {
              setCurrent(thematique);
            }}
            className={`thema ${!thematique.flux ? "noflux" : ""}`}
            key={thematique.name}
            style={{
              backgroundImage: `url(${thematique.img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            <div className="text">{thematique.name}</div>
          </div>
        );
      })}
    </div>
  );
}
export default Thematiques;
