import HomeBandeau from "./homeBandeau";
import LastNews from "./lastNews";
import FlipHomeServices from "./flipHomeServices";
import CarousselHares from "./carrouselHares";
import NewsEcho from "./newsEcho";
import OurValors from "./ourValors";
import CalltoActionService from "./calltoActionService";
import CalltoActionWhoIam from "./CalltoActionWhoIam";
import Contact from "./contact";
import MEGVideos from "./MEGVideos";

function Custom({ bloc, page }) {
  switch (bloc.class) {
    case "HomeBandeau":
      return <HomeBandeau {...bloc} />;
    case "LastNews":
      return <LastNews {...bloc} />;
    case "FlipHomeServices":
      return <FlipHomeServices {...bloc} />;
    case "CarousselHares":
      return <CarousselHares {...bloc} />;
    case "NewsEcho":
      return <NewsEcho {...bloc} />;
    case "OurValors":
      return <OurValors {...bloc} page={page} />;
    case "CalltoActionService":
      return <CalltoActionService />;
    case "CalltoActionWhoIam":
      return <CalltoActionWhoIam />;
    case "Contact":
      return <Contact />;
    case "MEGVideos":
      return <MEGVideos />;
    default:
      // console.log(bloc)
      throw new Error(`Unhandled action type: ${bloc.name}`);
  }
}

export default Custom;
