import axios from "axios";
import { toast } from "react-toastify";
export default function Config({ config }) {
  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  let grouped = groupBy(config, "type");
  const updateConfig = (e) => {
    const target = e.target;
    axios
      .put(`/config/${target.dataset.id}`, { value: target.value })
      .then(() => {
        toast(`Configuration ${target.dataset.key} mise à jour`);
      });
  };

  const uploadFile = (e) => {
    const target = e.target;
    var reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onloadend = function () {
      const preview = document.querySelector(`#preview-${target.dataset.id}`);
      preview.src = reader.result;
      axios.put(`/config/${target.dataset.id}`, {
        value: reader.result,
      });
    };
  };

  return (
    <div id="Config">
      {Object.entries(grouped).map(([key, value]) => {
        if (key !== "easteregg")
          return (
            <div className="group" key={key}>
              <h2>{key}</h2>
              {value.map((conf) => {
                if (key === "logo") {
                  return (
                    <div className="config" key={conf.id} data-id={conf.id}>
                      <label htmlFor={conf.key}>
                        {conf.key}
                        <img
                          src={conf.value}
                          alt={conf.key}
                          id={"preview-" + conf.id}
                        />
                        <input
                          type="file"
                          data-id={conf.id}
                          data-key={conf.key}
                          id={conf.key}
                          onChange={uploadFile}
                        />
                      </label>
                    </div>
                  );
                }
                return (
                  <div className="config" key={conf.id}>
                    <label className="name">
                      {conf.key}
                      <input
                        type={key === "color" ? "color" : "text"}
                        defaultValue={conf.value}
                        data-id={conf.id}
                        data-key={conf.key}
                        onBlur={updateConfig}
                      />
                    </label>
                  </div>
                );
              })}
            </div>
          );
      })}
    </div>
  );
}
