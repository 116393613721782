import axios from "axios";
import { useEffect, useState } from "react";
import Bloc from "../components/Bloc";
import SimpleContent from "./SimpleContent";

export default function Pages({ pages }) {
  //   console.log(pages);
  const [page, setPage] = useState(pages[0].path);
  const [blocs, setBlocs] = useState(false);
  const [rawBlocs, setRawBlocks] = useState(false);
  useEffect(() => {
    // console.log(page);
    // setPage(pages[0].path);
    // if (!blocs) {
    axios.get(`/page/${page}`).then(({ data }) => {
      let render = data
        // .filter((bloc) => {
        //   return !bloc.parent;
        // })
        .map((bloc, indexBloc) => {
          //   console.log(bloc.type);
          //   console.log(bloc);
          if (bloc.type !== "custom") return <DefaultBloc bloc={bloc} />;
          else {
            return <CustomBloc bloc={bloc} />;
          }
        });
      setRawBlocks(data);
      setBlocs(render);
    });
    // }
  }, [page]);

  return (
    <div id="Pages">
      <div className="links">
        {pages.map((page) => {
          return (
            <div
              className="linkPage"
              key={page.id}
              onClick={() => setPage(page.path)}
            >
              <div>{page.name}</div>
              {/* <div>{page.path}</div> */}
            </div>
          );
        })}
      </div>
      {/* <Content blocs={blocs} /> */}
      <SimpleContent blocs={rawBlocs} />
    </div>
  );
}

const Content = ({ blocs }) => {
  return <div className="content">{blocs}</div>;
};

const DefaultBloc = ({ bloc }) => {
  switch (bloc.type) {
    case "bloc":
      return <BlocForm bloc={bloc} />;
    case "title":
    case "sub-title":
      return <TitleForm bloc={bloc} />;
    case "calltoaction":
      return <CallToActionForm bloc={bloc} />;
    case "lilbar":
      //   return <div>{bloc.type}</div>;
      return (
        <div
          style={{
            background: "var(--triColor)",
            width: "30px",
            height: "2px",
          }}
        ></div>
      );

    default:
      return (
        <div>
          <label htmlFor={bloc.name}>
            {bloc.type} - {bloc.name} - {bloc.parent ? "true" : "false"} -{" "}
            {bloc.options}
            <input type="text" id={bloc.name} defaultValue={bloc.value} />
          </label>
        </div>
      );
  }
};

const CustomBloc = ({ bloc }) => {
  switch (bloc.class) {
    case "HomeBandeau":
      return (
        <div>
          {bloc.type} - {bloc.class} - {bloc.name} - {bloc.value} -{" "}
          {bloc.parent ? "true" : "false"}
        </div>
      );
    // case "LastNews":
    //   return <LastNews {...bloc} />;
    // case "FlipHomeServices":
    //   return <FlipHomeServices {...bloc} />;
    // case "CarousselHares":
    //   return <CarousselHares {...bloc} />;
    // case "NewsEcho":
    //   return <NewsEcho {...bloc} />;
    // case "OurValors":
    //   return <OurValors {...bloc} page={page} />;
    // case "CalltoActionService":
    //   return <CalltoActionService />;
    // case "CalltoActionWhoIam":
    //   return <CalltoActionWhoIam />;
    // case "Contact":
    //   return <Contact />;
    default:
      console.log(bloc);
      return false;
    //   throw new Error(`Unhandled action type: ${bloc.name}`);
  }
};

const BlocForm = ({ bloc }) => {
  let value = bloc.name.split("-")[1];
  return (
    <div>
      <h2>{bloc.type}</h2>
      <label htmlFor={bloc.name}>
        Nom du Bloc :
        <input type="text" id={bloc.name} defaultValue={value} />
      </label>
    </div>
  );
};

const TitleForm = ({ bloc }) => {
  return (
    <div>
      <h2>{bloc.type}</h2>
      <label htmlFor={bloc.name}>
        Texte du {bloc.type} :
        <input type="text" id={bloc.name} defaultValue={bloc.value} />
      </label>
    </div>
  );
};

const CallToActionForm = ({ bloc }) => {
  let [name, text, button, options] = bloc.value.split("|");
  return (
    <div className="CallToActionForm">
      <h2>{bloc.type}</h2>
      <label htmlFor={"name" + bloc.name}>
        Titre :
        <input type="text" id={"name" + bloc.name} defaultValue={name} />
      </label>
      <label htmlFor={"text" + bloc.name}>
        Texte :
        <textarea
          name={"text" + bloc.name}
          id={"text" + bloc.name}
          cols="30"
          rows="5"
        >
          {text}
        </textarea>
      </label>
      <label htmlFor={"button" + bloc.name}>
        Texte sur le Bouton :
        <input type="text" id={"button" + bloc.name} defaultValue={button} />
      </label>
      <label htmlFor={"options" + bloc.name}>
        Type :
        <input
          type="text"
          id={"options" + bloc.name}
          defaultValue={options}
          disabled
        />
      </label>
    </div>
  );
};
