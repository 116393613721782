import LastNews from "../lastNews";
import { useState, useEffect } from "react";

import Waiting from "../../Waiting";
import Simulateurs from "./Simulateurs.jsx";


import Parser from "rss-parser";
let parser = new Parser();

function SelectedOption({ flux, link }) {
  // console.log(link);
  const [news, setNews] = useState(false);
  useEffect(() => {
    setNews(false);
    if (flux) {
      parser.parseURL(flux).then(({ items }) => {
        setNews(items);
      });
    }
  }, [flux]);
  if (flux) {
    if (news) {
      return (
        <div>
          <LastNews {...{ value: 12, items: news, pagination: true }} />
        </div>
      );
    }
    return <Waiting />;
  }
  switch (link) {
    case "simulateur":
      return <Simulateurs />;

    default:
      return <div>PAS DE FLUX</div>;
  }
}
export default SelectedOption;
