import { useState } from "react";
import "./styles.scss";
function OurValors(props) {
  const tabs = props.page.filter((b) => b.type === "tab");
  const [current, setCurrent] = useState(0);
  const def = [
    {
      name: "H",
      img: "/static/H.png",
      order: 0,
      complet: "umain",
    },
    {
      name: "A",
      img: "/static/A.png",
      order: 1,
      complet: "udit",
    },
    {
      name: "R",
      img: "/static/R.png",
      order: 2,
      complet: "éactivité",
    },
    {
      name: "E",
      img: "/static/E.png",
      order: 3,
      complet: "xpertise",
    },
    {
      name: "S",
      img: "/static/S.png",
      order: 4,
      complet: "implicité",
    },
  ];

  function letterbyImg(text) {
    let firstLetter = text[0];
    let restText = text.slice(1, text.length);
    let letter = def.find((x) => x.name === firstLetter);
    return (
      <span data-letter={firstLetter}>
        <img src={letter.img} alt={firstLetter} />
        {restText}
      </span>
    );
  }
  function parseValue(value) {
    return value.split("\n").map((line, index) => {
      if (index === 0) {
        return <h4 key={index}>{line}</h4>;
      }
      return (
        <p className={index === 0 ? "strong" : ""} key={index}>
          {line}
        </p>
      );
    });
  }
  return (
    <div className={"OurValors " + props.value}>
      <ul className="listTab">
        {tabs.map((tab, index) => {
          return (
            <li
              className={index === current ? "current" : ""}
              key={`li-${index}`}
              onClick={() => {
                setCurrent(index);
              }}
            >
              {letterbyImg(tab.name)}
            </li>
          );
        })}
      </ul>
      <div className="currentTab">{parseValue(tabs[current].value)}</div>
    </div>
  );
}
export default OurValors;
