import axios from "axios";
import "./styles/main.scss";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Nav from "./components/Nav";
import Page from "./components/Page";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Waiting from "./components/Waiting";
import BackTop from "./components/partial/BackTop";

library.add(fab, fas, far);

function App() {
  const location = useLocation();
  const [config, setConfig] = useState(false);
  const [styles, setStyles] = useState(false);
  const [pages, setPages] = useState(false);
  const backtop = useRef(false);
  const navbar = useRef(false);

  useEffect(() => {
    if (!config) {
      axios.get("/config").then(({ data }) => {
        const { pages, config } = data;
        setConfig(config);
        setStyles(config.filter((x) => x.type === "color"));
        setPages(pages);
      });
    }
  }, [config]);
  useEffect(() => {
    if (styles) {
      styles.forEach((color) => {
        document.body.style.setProperty(`--${color.key}`, color.value);
      });
    }
  }, [styles]);

  window.onscroll = () => {
    if (window.pageYOffset >= window.innerHeight / 2) {
      backtop.current.classList.remove("hide");
    } else {
      backtop.current.classList.add("hide");
    }
    if (!(window.innerWidth <= 1200)) {
      if (window.pageYOffset >= 120) {
        navbar.current.classList.add("sticky");
      } else {
        navbar.current.classList.remove("sticky");
      }
    }
  };

  if (!config || !pages || !styles) {
    return (
      <div className="App">
        <Waiting />
      </div>
    );
  }
  return (
    <div className="App">
      <Header {...{ config }} />
      <Nav {...{ pages, navbar, config, link: location.pathname }} />
      <Page {...{ config, link: location.pathname }} />
      <BackTop backtop={backtop} />
      <Footer {...{ config }} />
    </div>
  );
}

export default App;
