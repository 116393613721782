import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BackTop({ backtop }) {
  return (
    <a
      className="backTop hide"
      ref={backtop}
      href="#top"
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <FontAwesomeIcon icon="arrow-up" />
    </a>
  );
}
export default BackTop;
