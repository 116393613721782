import Waiting from "../components/Waiting";

export default function SimpleContent({ blocs }) {
  if (!blocs) return <Waiting />;
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>type</th>
            <th>name</th>
            <th>value</th>
            <th>class</th>
            <th>parent</th>
            <th>options</th>
          </tr>
        </thead>
        <tbody>
          {blocs.map((bloc, index) => {
            return (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    style={{ width: "100px" }}
                    defaultValue={bloc.type}
                  />
                </td>
                <td>
                  <input type="text" defaultValue={bloc.name} />
                </td>
                <td>
                  <input type="text" defaultValue={bloc.value} />
                </td>
                <td>
                  <input type="text" defaultValue={bloc.class} />
                </td>
                <td>
                  <input type="text" defaultValue={bloc.parent} />
                </td>
                <td>
                  <input type="text" defaultValue={bloc.options} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="btn" style={{ width: "100px" }}>
        Valider
      </div>
    </div>
  );
}
