import LinkNav from "./partial/LinkNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import Search from "./Search";
import { Link } from "react-router-dom";

function Nav({ pages, navbar, config, link }) {
  let socials = config.filter((x) => x.type === "social");
  let logo = config.find((x) => x.type === "logo" && x.key === "mobil");
  let tel = config.find((x) => x.type === "contact" && x.key === "tel");
  let location = config.filter((x) => x.type === "location");
  let menu = useRef(false);
  useEffect(() => {
    let page = pages.find((x) => x.path === link.replace("/", ""));
    let nameSite = config.find((x) => x.key === "nameSite").value;
    if (page && nameSite) {
      document.title = `${page.name} | ${nameSite}`;
      // console.log(link.replace("/", ""), page.name, nameSite);
    }
  });
  return (
    <nav ref={navbar}>
      <div
        className="burgerbutton"
        onClick={() => {
          menu.current.classList.toggle("active");
        }}
      >
        <FontAwesomeIcon icon={["fas", "bars"]} />
      </div>
      <Link
        to="/"
        className="logo"
        style={{
          backgroundImage: `url("${logo.value}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left",
        }}
      ></Link>
      <ul className="menu" ref={menu}>
        {pages
          .filter((x) => x.child === "FALSE")
          .map((page, index) => {
            return (
              <LinkNav {...{ page, pages, link, menu }} key={`menu-${index}`} />
            );
          })}
        <Search />
        {/* <li> */}
        {/* <FontAwesomeIcon icon={["fas", "search"]} /> */}
        {/* </li> */}
      </ul>
      <ul className="social">
        {socials.map((social, index) => {
          return (
            <li key={index}>
              <a href={social.value}>
                <FontAwesomeIcon icon={["fab", social.key]} />
              </a>
            </li>
          );
        })}
      </ul>
      <div className="button">
        <div className="tel">
          <div className="icn">
            <a href={`tel:${tel.value}`} rel="noreferrer" name="tel">
              <FontAwesomeIcon icon="phone-alt" />
            </a>
          </div>
        </div>
        <div className="location">
          <div className="icn">
            <a
              href={`http://maps.google.com/?q=${location[0].value} ${location[1].value}`}
              target="_blank"
              rel="noreferrer"
              name="location"
            >
              <FontAwesomeIcon icon="map-marker-alt" />
            </a>
          </div>
        </div>
        <div className="btn connect nomargin nopadding">Espace&nbsp;Client</div>
      </div>
    </nav>
  );
}

export default Nav;
