import "./styles.scss";
import { Link } from "react-router-dom";
function CalltoActionService() {
  let def = [
    {
      name: "Se lancer",
      link: "/letgo",
      img: "/static/fusee-140x100.png",
      action: `Parce que nous considérons que chaque projet est différent, et parce que chaque projet n’a pas les mêmes besoins, nous proposons à chacun une mission qui répond à ses exigences.`,
    },
    {
      name: "Au quotidien",
      link: "/daily",
      img: "/static/QUOTIDIEN2-1-140x100.png",
      action: `Partenaires de votre entreprise, nous vous accompagnons également en tant que dirigeant pour un équilibre entre vos intérêts personnels et ceux de votre entreprise.`,
    },
    {
      name: "Se développer",
      link: "/dev",
      img: "/static/DEV-140x100.png",
      action: `Parce que beaucoup de questions se posent dans le cadre d’un développement, nous sommes là pour vous sécuriser et vous aider dans vos prises de décision`,
    },
    {
      name: "Transmettre",
      link: "/transmit",
      img: "/static/transmission-1-140x100.png",
      action: `Nous sommes à votre écoute pour trouver ensemble les meilleures solutions de transmission et les concrétiser`,
    },
  ];
  return (
    <div className="CalltoActionService">
      <div className="moreDetail">
        <div className="text">{def[0].action}</div>
        <a className="btn" href="/contact">
          VOUS SOUHAITEZ EN SAVOIR PLUS&nbsp;?
        </a>
      </div>
      <div className="moreFar">
        <h3>Aller Plus Loin</h3>
        <div className="separator"></div>
        <div className="links">
          {def
            .filter((x) => x.link !== window.location.pathname)
            .map((link, index) => {
              // console.log(link);
              return (
                <Link
                  className="link"
                  key={index}
                  to={link.link}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={link.img} alt={link.name} />
                  <div className="text">{link.name}</div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CalltoActionService;
