import "./styles.scss";
import Parser from "rss-parser";
import { useEffect, useState } from "react";
import Waiting from "../../Waiting";
import LastNews from "../lastNews";

import Menu from "./menu";
import Thematiques from "./Thematiques";
import SelectedOption from "./SelectedOption";
let parser = new Parser();

function NewsEcho({ value }) {
  const [news, setNews] = useState(false);
  const [current, setCurrent] = useState(false);
  useEffect(() => {
    if (!news) {
      parser
        .parseURL("https://actualites.hares-expertise.fr/rss/actus/all")
        .then((data) => {
          let { items } = data;
          setNews(items);
        });
    }
  });
  if (!news) {
    return <Waiting />;
  }
  if (!current) {
    return (
      <div id="NewsEcho">
        <div className="title">
          <h1>Actualité</h1>
        </div>
        <div className="bloc all column">
          <Menu setCurrent={setCurrent} />
        </div>
        <div className="sub-title all invert">
          <h2>Dernières actualités</h2>
        </div>
        <LastNews items={news.slice(0, 3)} />
        <div className="sub-title all colored">
          <h2>Les thématiques</h2>
        </div>
        <Thematiques styles="colored" {...{ setCurrent }} />
      </div>
    );
  }
  return (
    <div id="NewsEcho">
      <div className="title">
        <h1>Actualité | {current.title}</h1>
      </div>
      <div className="bloc all column">
        <Menu setCurrent={setCurrent} />
      </div>
      <SelectedOption {...current} />
      {/* {current.name} */}
    </div>
  );
}

export default NewsEcho;
