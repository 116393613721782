import "./style.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import Waiting from "../components/Waiting";
import Content from "./Content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Admin() {
  const [config, setConfig] = useState(false);
  const [pages, setPages] = useState(false);
  const [action, setAction] = useState("pages");
  useEffect(() => {
    if (!config) {
      axios.get("/config").then(({ data }) => {
        const { pages, config } = data;
        setConfig(config);
        setPages(pages);
      });
    }
    let app = document.querySelector(".App");
    if (app) {
      app.classList.add("admin");
    }
    const handdleEnter = (e) => {
      if (e.key === "Enter") {
        e.target.blur();
        return false;
      }
    };
    window.addEventListener("keydown", handdleEnter);

    return () => {
      window.removeEventListener("keydown", handdleEnter);
      if (app) {
        app.classList.remove("admin");
      }
    };
  }, [config]);

  if (!config || !pages) {
    return <Waiting />;
  }

  return (
    <div id="Admin">
      <h1 onClick={() => setAction("")}>Admin Hares</h1>
      {/* {JSON.stringify(config, null, 2)} */}
      <div className="nav">
        <div className="link" onClick={() => setAction("config")}>
          Config
        </div>
        <div className="link" onClick={() => setAction("pages")}>
          Pages
        </div>
      </div>
      {/* <div className="content">{action}</div> */}
      <section>
        <Content action={action} config={config} pages={pages} />
      </section>
      <ToastContainer position="bottom-right" autoClose={1000} />
    </div>
  );
}
