import { Link } from "react-router-dom";
import "./styles.scss";
function FlipHomeServices({ name, parent, value }) {
  // console.log("FlipHomeServices", name, parent, value);
  let imgFront =
    "/static/se-lancerOK-1.jpg";
  let title = "";
  let text = "";
  switch (value) {
    case "letgo":
      imgFront =
        "/static/fusee-150x150.png";
      title = "Se lancer";
      text = "Vous avez un projet ? Nous sommes là pour vous accompagner";
      break;
    case "daily":
      imgFront =
        "/static/QUOTIDIEN2-1-150x150.png";
      title = "Au quotidien";
      text = "Besoin d’aide dans votre gestion ?";
      break;
    case "dev":
      imgFront =
        "/static/DEV-150x150.png";
      title = "Se développer";
      text = "Comment y parvenir en évitant les écueils ?";
      break;
    case "transmit":
      imgFront =
        "/static/transmission-1-150x150.png";
      title = "Transmettre";
      text = "Quand et comment ?";
      break;
    default:
      return false;
  }
  return (
    <Link className="FlipHomeServices" to={`/${value}`}>
      <div className="flip-bloc">
        <div className="inner">
          <div
            className="front"
            style={{
              backgroundImage: `url("${imgFront}")`,
              backgroundSize: "30%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top 3rem center",
            }}
          >
            <div className="name">{title}</div>
          </div>
          <div className="back">
            <div className="name">{title}</div>
            <div className="text">{text}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}
export default FlipHomeServices;
