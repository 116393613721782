import "./styles.scss";
import { useState } from "react";
function CarousselHares() {
  const [running, setRunning] = useState(false);
  const def = [
    {
      name: "H",
      img: "/static/H.png",
      order: 0,
      complet: "umain",
    },
    {
      name: "A",
      img: "/static/A.png",
      order: 1,
      complet: "udit",
    },
    {
      name: "R",
      img: "/static/R.png",
      order: 2,
      complet: "éactivité",
    },
    {
      name: "E",
      img: "/static/E.png",
      order: 3,
      complet: "xpertise",
    },
    {
      name: "S",
      img: "/static/S.png",
      order: 4,
      complet: "implicité",
    },
  ];
  function ShowLetter(div, i) {
    if (!running) {
      div.style.animation = `reveal${i + 1} 500ms ease-in-out forwards`;
      setRunning(true);
      setTimeout(() => {
        setRunning(false);
      }, 550);
    }
  }
  function HideLetter(div, i) {
    if (!running) {
      div.style.animation = `hide${i + 1} 1s ease-in-out forwards`;
      setRunning(true);
      setTimeout(() => {
        setRunning(false);
      }, 550);
    }
  }
  return (
    <div className="CarousselHares">
      {def.map((letter, i) => {
        return (
          <div
            className="letter"
            key={i}
            onMouseEnter={(event) => {
              let complet = event.currentTarget.querySelector(".complet");
              ShowLetter(complet, i);
            }}
            onClick={(event) => {
              let complet = event.currentTarget.querySelector(".complet");
              let finder = complet.style.animation.match(/reveal/i);
              if (!finder) {
                ShowLetter(complet, i);
              } else {
                HideLetter(complet, i);
              }
            }}
            onMouseLeave={(event) => {
              let complet = event.currentTarget.querySelector(".complet");
              HideLetter(complet, i);
            }}
          >
            <div className={`name ${letter.name}`}>
              <img src={letter.img} alt={letter.name} />
            </div>
            <div className={`complet ${letter.name}`}>{letter.complet}</div>
          </div>
        );
      })}
    </div>
  );
}
export default CarousselHares;
