import { useRef } from "react";
import "./style.scss";

import axios from "axios";

function Contact() {
  const inputName = useRef(false);
  const inputMail = useRef(false);
  const inputObject = useRef(false);
  const inputMessage = useRef(false);
  const inputAccept = useRef(false);
  return (
    <div className="Contact bloc large">
      <div className="sub-bloc">
        <h4>Parlons de la réalité de votre entreprise ensemble…</h4>
        <p>
          Pour en savoir plus sur nos compétences et nos missions, n’hésitez pas
          à nous contacter. Nous nous ferons un plaisir de vous répondre dans
          les meilleurs délais et de vous rencontrer pour définir ensemble notre
          collaboration.
        </p>
        <div className="form">
          <div className="field">
            <input
              type="text"
              name="name"
              placeholder="Michel Dupont"
              ref={inputName}
              onClick={() => {
                inputName.current?.classList.remove("error");
              }}
            />
            <label htmlFor="name">Votre nom *</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="mail"
              placeholder="m.dupont@entreprise.fr"
              ref={inputMail}
              onClick={() => {
                inputMail.current?.classList.remove("error");
              }}
            />
            <label htmlFor="mail">Votre adresse de messagerie *</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="object"
              placeholder="Demande de contact"
              ref={inputObject}
              onClick={() => {
                inputObject.current?.classList.remove("error");
              }}
            />
            <label htmlFor="object">Objet *</label>
          </div>
          <div className="field">
            <textarea
              name="message"
              placeholder="Bonjour, ..."
              ref={inputMessage}
              onClick={() => {
                inputMessage.current?.classList.remove("error");
              }}
            ></textarea>
            <label htmlFor="message">Votre message</label>
          </div>
          {/* <div className="field"></div> */}

          <p className="accept">
            <input type="checkbox" name="accept" ref={inputAccept} />
            <label htmlFor="accept">
              J’accepte que les informations recueillies sur ce formulaire
              soient enregistrées dans un fichier informatisé par HARES pour la
              gestion de sa clientèle. Elles seront conservées pendant 1 an et
              seront destinées au service marketing et au service commercial
              établis au sein de l'entreprise. Conformément à la loi «
              informatique et libertés », vous pourrez exercer votre droit
              d'accès aux données vous concernant et les faire rectifier en
              contactant : contact@hares-expertise.fr
            </label>
          </p>
          <div
            className="btn"
            onClick={() => {
              let errors = ValidForm();
              if (errors.length > 0) {
                errors.forEach((error) => {
                  // console.log(error);
                  switch (error.input) {
                    case "name":
                      inputName.current?.classList.add("error");
                      break;
                    case "object":
                      inputObject.current?.classList.add("error");
                      break;
                    case "mail":
                      inputMail.current?.classList.add("error");
                      break;
                    default:
                      return;
                  }
                });
              } else {
                // console.log("SEND MAIL !!!!");
                const name = inputName.current?.value;
                const mail = inputMail.current?.value;
                const object = inputObject.current?.value;
                const message = inputMessage.current?.value;
                const accept = inputAccept.current?.checked;
                axios
                  .post("/sendMail", {
                    name,
                    mail,
                    object,
                    message,
                    accept,
                  })
                  .then(({ data }) => {
                    console.log(data);
                  });
              }
            }}
          >
            Envoyer
          </div>
          <p>* Champ obligatoire</p>
        </div>
      </div>
      <div className="sub-bloc">
        <h4>Adresse</h4>
        <iframe
          title="Google Map Hares"
          loading="lazy"
          style={{ border: 0 }}
          src={
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2784.108945666975!2d4.754659102630235!3d45.74896179497859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed0ab486955dbaaf!2sHARES!5e0!3m2!1sfr!2sfr!4v1629731975436!5m2!1sfr!2sfr"
          }
          width={650}
          height={460}
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );

  function ValidForm() {
    const name = inputName.current?.value;
    const mail = inputMail.current?.value;
    const object = inputObject.current?.value;
    const message = inputMessage.current?.value;
    const accept = inputAccept.current?.checked;
    // console.log(name, mail, object, message, accept);
    let error = [];
    if (!name) {
      error.push({
        input: "name",
        type: "empty",
      });
    }
    if (!object) {
      error.push({
        input: "object",
        type: "empty",
      });
    }

    if (!mail) {
      error.push({
        input: "mail",
        type: "empty",
      });
    } else {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(mail).toLowerCase())) {
        error.push({
          input: "mail",
          type: "notvalid",
        });
      }
    }

    return error;
  }
}
export default Contact;
