import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Menu({ setCurrent }) {
  const defMenu = [
    {
      name: "Actualités",
      title: "News",
      link: "actu",
      icon: "newspaper",
      flux: "https://actualites.hares-expertise.fr/rss/actus/120",
    },
    {
      name: "Dossiers",
      title: "Dossiers",
      link: "dossiers",
      icon: "book",
      flux: "https://actualites.hares-expertise.fr/rss/dossiers/all/120",
    },
    {
      name: "Simulateurs",
      title: "Simulateurs",
      link: "simulateur",
      icon: "tasks",
      flux: false,
    },
    {
      name: "Dessins",
      title: "Dessins",
      link: "dessins",
      icon: "image",
      flux: "https://actualites.hares-expertise.fr/rss/dessin/120",
    },
    {
      name: "Chiffres Utiles",
      title: "Chiffres Utiles",
      link: "chiffres-utiles",
      icon: "info",
      flux: false,
    },
    {
      name: "Bourse",
      title: "Bourse",
      link: "bourse",
      icon: "chart-line",

      flux: false,
    },
    {
      name: "La Minute de L'expert",
      title: "La Minute de L'expert",
      link: "minute-expert",
      icon: "video",
      flux: "https://actualites.hares-expertise.fr/rss/minute-expert/120",
    },
    {
      name: "Questions-Réponses",
      title: "Questions-Réponses",
      link: "questions-reponses",
      icon: "question-circle",
      flux: false,
    },
    {
      name: "Echéanciers",
      title: "Echéanciers",
      link: "echeanciers",
      icon: "calendar",
      flux: false,
    },
  ];
  return (
    <ul className="menu">
      {defMenu.map((link) => {
        return (
          <li
            className={`btn nomargin ${!link.flux ? "noflux" : ""}`}
            key={link.link}
            onClick={() => {
              setCurrent(link);
            }}
          >
            <FontAwesomeIcon icon={link.icon} />
            {link.name}
          </li>
        );
      })}
    </ul>
  );
}
export default Menu;
