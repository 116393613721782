import "./styles.scss";

function HomeBandeau(props) {
  let [left, right] = props.value.split("|");
  return (
    <div className="homeBandeau">
      <div className="left">{left}</div>
      <div className="right">{right}</div>
    </div>
  );
}

export default HomeBandeau;
