/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useReducer } from "react";
import axios from "axios";
import Waiting from "./Waiting";
import Bloc from "./Bloc";

function Page({ link, config }) {
  function reducer(state, action) {
    switch (action.type) {
      case "update":
        return link;
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  const [page, setPage] = useState(false);
  const [url, dispatch] = useReducer(reducer, false);

  useEffect(() => {
    if (!page) {
      if (link === "/") link = "/home";
      axios.get(`/page${link}`).then(({ data }) => {
        setPage(data);
      });
    }

    if (url !== link) {
      dispatch({ type: "update" });
      setPage(false);
    }
  }, [url, page, link]);
  if (!page) {
    return <Waiting />;
  }

  let render = page
    .filter((bloc) => {
      return !bloc.parent;
    })
    .map((bloc, indexBloc) => {
      return (
        <Bloc
          bloc={bloc}
          indexBloc={indexBloc}
          page={page}
          key={"bloc-" + indexBloc}
        />
      );
    });

  return <main className="page">{render}</main>;
  // return <Waiting />;
}

export default Page;
