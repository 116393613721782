import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
function Search() {
  const [Open, setOpen] = useState(false);
  const inputSearch = useRef(false);
  useEffect(() => {
    if (Open) {
      inputSearch.current.focus();
      window.addEventListener("keypress", (event) => {
        if (event.code.toLocaleLowerCase() === "enter") {
          redirectSearch();
        }
      });
    }
  });
  const redirectSearch = () => {
    if (inputSearch.current) {
      window.location.replace(
        `https://actualites.hares-expertise.fr/recherche?query=${inputSearch.current?.value}`
      );
    }
  };
  return (
    <>
      <li
        onClick={() => {
          setOpen(!Open);
        }}
      >
        <FontAwesomeIcon icon={["fas", "search"]} />
      </li>
      <div
        id="modalSearch"
        data-open={Open}
        onClick={(event) => {
          if (event.target.id === "modalSearch") {
            setOpen(false);
          }
        }}
      >
        <div className="body">
          <div
            className="close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </div>
          <input
            type="text"
            placeholder="votre recherche..."
            ref={inputSearch}
          />
          <div
            className="btn"
            onClick={() => {
              redirectSearch();
            }}
          >
            Rechercher
          </div>
        </div>
      </div>
    </>
  );
}
export default Search;
