import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer({ config }) {
  let logo = config.find((x) => x.type === "logo" && x.key === "black");
  let contact = config.filter((x) => x.type === "contact");
  let location = config.filter((x) => x.type === "location");
  let horaire = config.filter((x) => x.type === "horaire");
  let socials = config.filter((x) => x.type === "social");
  return (
    <footer>
      <div
        className="logo"
        style={{
          backgroundImage: `url("${logo.value}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left",
        }}
      ></div>
      <div className="infos">
        {location[0] ? <p>{location[0].value}</p> : false}
        {location[1] ? <p>{location[1].value}</p> : false}
        {horaire[0] ? <p>{horaire[0].value}</p> : false}
        {horaire[1] ? <p>{horaire[1].value}</p> : false}
      </div>
      <div className="infos">
        {contact[0] ? (
          <p>
            <a href={`tel:${contact[0].value}`}>{contact[0].value}</a>
          </p>
        ) : (
          false
        )}

        {contact[1] ? (
          <p>
            <a href={`mailto:${contact[1].value}`}>{contact[1].value}</a>
          </p>
        ) : (
          false
        )}

        <div className="socials">
          {socials.map((social) => {
            // console.log(social);
            return (
              <a href={social.value} key={social.key}>
                <div className="icone">
                  <FontAwesomeIcon icon={["fab", social.key]} />
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className="newletter">
        <a href="https://actualites.hares-expertise.fr/inscription-newsletter">
          Inscrivez-vous à notre newsletter
        </a>
      </div>
    </footer>
  );
}
export default Footer;
