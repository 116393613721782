import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LinkNav({ pages, page, link, menu }) {
  let childs = pages.filter((x) => x.child === page.parent);
  //   console.log(childs);
  if (page.path === "home") page.path = "";
  return (
    <li
      key={page.parent}
      className={`${
        link && link.replace("/", "") === page.path ? "active" : ""
      }`}
      onClick={(e) => {
        if (e.target.nodeName === "A" && menu) {
          menu.current.classList.toggle("active");
        }
      }}
    >
      {page.link ? (
        page.type === "INTERN" ? (
          <Link to={`/${page.path}`}>{page.name}</Link>
        ) : (
          <a
            href={page.path}
            onClick={(e) => {
              // e.preventDefault();
              menu.current.classList.toggle("active");
            }}
          >
            {page.name}
          </a>
        )
      ) : (
        page.name
      )}

      {childs.length > 0 ? (
        <>
          <div className="openSubMenu">
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />
          </div>
          <ul className="subMenu">
            {childs.map((page, index) => {
              return (
                <LinkNav page={page} pages={pages} key={`sub-menu-${index}`} />
              );
            })}
          </ul>
        </>
      ) : (
        false
      )}
    </li>
  );
}

export default LinkNav;
