import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
function Header({ config }) {
  let logo = config.find((x) => x.type === "logo" && x.key === "normal");
  let contact = config.filter((x) => x.type === "contact");
  let location = config.filter((x) => x.type === "location");
  let tel = config.find((x) => x.type === "contact" && x.key === "tel");
  let nameSite = config.find((x) => x.key === "nameSite").value;
  return (
    <header id="top">
      <Link
        to="/"
        className="logo"
        style={{
          backgroundImage: `url("${logo.value}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left",
        }}
      ></Link>
      <div className="info">
        {contact.length > 0 ? (
          <div className="contact">
            <div className="icn">
              <a href={`tel:${tel.value}`} rel="noreferrer" name="tel">
                <FontAwesomeIcon icon="phone-alt" />
              </a>
            </div>
            <div className="text1">{contact[0] ? contact[0].value : false}</div>
            <div className="text2">
              <a href={contact[1] ? `mailto:${contact[1].value}` : false}>
                {contact[1] ? contact[1].value : false}
              </a>
            </div>
          </div>
        ) : (
          false
        )}
        {location.length > 0 ? (
          <div className="location">
            <div className="contact">
              <div className="icn">
                <a
                  href={`http://maps.google.com/?q=${nameSite}`}
                  target="_blank"
                  rel="noreferrer"
                  name="location"
                >
                  <FontAwesomeIcon icon="map-marker-alt" />
                </a>
              </div>
              <div className="text1">
                {location[0] ? location[0].value : false}
              </div>
              <div className="text2">
                {location[1] ? location[1].value : false}
              </div>
            </div>
          </div>
        ) : (
          false
        )}

        <Link className="btn nomargin" to="/tools">
          Espace&nbsp;Client
        </Link>
      </div>
    </header>
  );
}
export default Header;
